import React from "react";
import {
  Box,
  Image,

} from '@chakra-ui/react';
import Layout from "../../components/layout"
import Footer from "../../components/footer"
import Step from 'src/containers/referral/step'
import How from 'src/containers/referral/how'
import Question from 'src/containers/referral/questions'
function Referral() {
  return (
    <Layout showFooter={false}>
      <Box mt="80px" pt={['24px', '80px']}>
        <Image src="/referral-banner.webp" mb="40px" />
        <How />
        <Step />
        <Question />
        <Footer />
      </Box >
    </Layout >
  );
}

export default Referral;

export const Head = () => (
  <>
    <title>XTRIP REFERRAL</title>
    <meta name="description" content={`XTRIP REFERRAL`} />
  </>
)