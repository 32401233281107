import React from "react";
import {
  Box,
  Text,
  Center,
  Heading,
  Image,
  Flex,
} from '@chakra-ui/react';
function Question() {
  const data = [
    {
      question: "Is there a limit for this referral program?",
      answer: "Refer to as many people as you like! We don't have a limit for the number of people you refer to."
    },
    {
      question: "How can I know if any of my friends joined xtrip through this referral program?",
      answer: "Track your referee(s) progress by looking at Your referral history in the Refer a friend section."
    },

  ]
  return (
    <Box
      color="#fff"
      px={{ base: 4, xl: 32, '2xl': 64 }}
      mb={{ base: 32, '2xl': 48 }}
    >
      <Heading fontSize={{ base: '20px', md: '50px' }} fontWeight="800" mb={{ base: '40px', lg: '80px' }} >
        <Center>
          Common questions
        </Center>
      </Heading>
      <Box>
        {data.map((item, index) => (
          <Flex borderRadius="16px" gap={4} >
            <Box pt="4px">
              <Image src={`/referral/forward.webp`} w="20px" />

            </Box>
            <Flex flex={1} flexDirection="column" mb="60px">
              <Text fontSize="20px" fontWeight="800" mb="16px" >
                {item.question}
              </Text>
              <Text fontSize="20px" fontWeight="400" >
                {item.answer}
              </Text>
            </Flex>

          </Flex>
        ))}
      </Box>
    </Box>
  );
}

export default Question;
