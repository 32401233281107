import React from "react";
import {
  Box,
  Text,
  Center,
  Heading,
  Grid,
  Image,
  Flex,
} from '@chakra-ui/react';
function Step() {
  const data = [
    {
      id: "Choose “Account”"
    },
    {
      id: "Click “Invite your friends”"
    },
    {
      id: "Copy the code and send it to your friends"
    }
  ]
  return (
    <Box
      color="#fff"
      px={{ base: 4, xl: 32, '2xl': 64 }}
      mb={{ base: 32, '2xl': 48 }}
    >
     <Heading fontSize={{ base: '20px', md: '50px' }} fontWeight="800" mb={{ base: '40px', lg: '80px' }} >
        <Center>
          Get your invitation code
        </Center>
      </Heading>
      <Grid templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} minH={"60vh"} gap={{ base: '24px', md: 24, 'xl': 48 }}>
        {data.map((item, index) => (
          <Flex borderRadius="16px" flexDirection="column" >
            <Image src={`/referral/step-${index + 1}.webp`} mb={{ base: 4, xl: 12 }} />
            <Text fontSize={{ base: '14px', lg: '20px' }} fontWeight="800" mb="30px" textAlign="center">
              {item.id}
            </Text>
          </Flex>
        ))}

      </Grid>
    </Box>
  );
}

export default Step;
