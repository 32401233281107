import React from "react";
import {
  Box,
  Text,
  Center,
  Heading,
  Grid,
  Image,
  Flex,
  Spacer,
} from '@chakra-ui/react';

function Referral() {
  const data = [
    {
      title: "Invite friends who are new to xtrip",
      content: "Introduce xtrip app by sending your Referral code to your friends."
    },
    {
      title: " Friends to sign up and activate their xtrip accounts",
      content: "Introduce xtrip app by sending your Referral code to your friends."
    },
    {
      title: "Get 5 GXT",
      content: "Both of you get 5 GXT once your friends: <br /><li> Own an NFT; or</li><li> Own no NFT and have 20 actions with spots (including register new spots, add overview/photo/review)</li>"
    }
  ]
  return (

    <Box
      color="#fff"
      pt={16}
      px={{ base: 4, xl: 32, '2xl': 64 }}
      mb={{ base: 32, '2xl': 48 }}

    >
      <Heading fontSize={{ base: '20px', md: '50px' }} fontWeight="800" mb={{ base: 16, '2xl': 32 }} >
        <Center>
          {/* <FormattedMessage id="litepaper.sec2_title_1" /> */}
          Invite Friends. Get 5 GXT
        </Center>
      </Heading>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }} gap={{ base: '32px' }}>
        {data.map((item, index) => (
          <Flex borderRadius="16px" bg="#132035" flexDirection="column">
            <Flex px="24px" flexDirection="column">
              <Image src={`/referral-${index + 1}.webp`} mb={{ base: '8px', xl: '16px' }} />
              <Heading fontSize={{ base: '20px', md: '40px' }} fontWeight="700" mb="60px">
                {item.title}
              </Heading>
              <Text fontSize="20px" fontWeight="500" mb="30px" dangerouslySetInnerHTML={{ __html: item.content }} />
            </Flex>
            <Spacer />
            <Box w="100%" h="20px" bg="linear-gradient(89.99deg, #122443 2%, rgba(25, 86, 184, 0) 97.71%)"
              transform="rotate(-180deg)"
              borderTopLeftRadius="16px"
              borderTopRightRadius="16px"
            />
          </Flex>
        ))}

      </Grid>
    </Box>

  );
}

export default Referral;

